<template>
    <div class="container">
        <content-title :nav="nav"></content-title>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane label="用户" name="user"></el-tab-pane>
            <el-tab-pane label="机构" name="mec"></el-tab-pane>
            <el-tab-pane label="其他" name="other"></el-tab-pane>
        </el-tabs>
        <!-- <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
            <el-form-item label="访问对象">
                <el-input v-model="user_name" placeholder="请输入名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="planStatus" placeholder="请选择" clearable>
                    <el-option label="待完成" :value="0">
                    </el-option>
                    <el-option label="已取消" :value="1">
                    </el-option>
                    <el-option label="已完成" :value="2">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间">
                <el-date-picker v-model="timeRange" type="daterange" @change="changeTime" clearable
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button @click="search" style="color: #ffffff; background-color: #ff7e00">查询</el-button>
                <el-button @click="openAddPlan" style="color: #ffffff; background-color: #ff7e00">新增</el-button>
            </el-form-item>
        </el-form> -->
        <el-table :data="tableData">
            <el-table-column prop="user_name" label="用户"> <template v-slot="{ row }">
                    <div class="flex">
                        <span>{{ row.user_name }}</span>
                    </div>
                </template></el-table-column>
            <el-table-column prop="admin_name" label="顾问"></el-table-column>
            <el-table-column prop="type" label="回访对象类型">
                <template v-slot="{ row }">
                    <p v-if="row.type == 1">注册用户</p>
                    <p v-if="row.type == 2">入驻机构</p>
                    <p v-if="row.type == 3">其他(待入驻)</p>
                </template>
            </el-table-column>
            <el-table-column prop="count" label="计划数量"></el-table-column>


            <el-table-column label="操作">
                <template v-slot="{ row }">
                    <el-button type="success" size="small" @click="toList(row)">查看计划</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy @currentPageChange="currentPageChange" :currentPage="currentPage" :total="total"></paging-fy>
    </div>
</template>
  
<script>
import adminSelect from "@/components/select/adminSelect.vue"

export default {
    components: {
        adminSelect
    },
    data() {
        return {
            nav: { firstNav: "顾问管理中心", secondNav: "访问对象" },
            activeName: 'all',
            tableData: [],
            currentPage: 1,
            total: 0,
            name: '',
            manager: JSON.parse(localStorage.getItem("managerInfo")),
            dialogVisible: false,
            planForm: {},
            recordForm: {},
            recordShow: false,
            curPlan: {},
            mode: 'add',
            user_name: "",
            planStatus: "",
            planStartTime: '',
            planEndTime: '',
            timeRange: [],
            planContentShow: false,
            planContent: '',
            recordContentShow: false,
            recordContent: '',
            type:''
        };
    },
    created() {
        this.getPlanList()
    },
    methods: {
        toList(row) {
            this.$router.push({
                name: 'userPlanList',
                params: {
                    user_name: row.user_name
                }
            });
        },
        handleClick(v) {
            this.currentPage = 1
            switch (v.name) {
                case "all":
                    this.type = ''
                    this.getPlanList()
                    break;
                case "user":
                    this.type = 1
                    this.getPlanList()
                    break;
                case "mec":
                    this.type = 2
                    this.getPlanList()
                    break;
                case "other":
                    this.type = 3
                    this.getPlanList()
                    break;
            }
        },
        search() {
            this.currentPage = 1
            this.getPlanList()
        },
        todetails(row) {
            this.$router.push({
                name: 'consultantDetails',
                params: {
                    id: row.id
                }
            });
        },
        changeTime(v) {
            if (v.length >= 2) {
                this.planStartTime = v[0]
                this.planEndTime = v[1]
            } else {
                this.planStartTime = new Date().Format("yyyy-MM-dd") + ' 00:00:00'
                this.planEndTime = new Date().Format("yyyy-MM-dd") + ' 23:59:59'
            }
        },
        getPlanList() {
            let data = {
                pageSize: 10,
                currentPage: this.currentPage,
                type:this.type || null
            }
            let url = "/user/consultantPlan/queryUserTypeList";
            this.$axios
                .get(url, {
                    params: data,
                })
                .then((res) => {
                    this.tableData = res.data.data.rows
                    this.total = res.data.data.total
                });
        },
        currentPageChange(v) {
            this.currentPage = v
            this.getPlanList()
        },

    },
};
</script>
  
<style lang="less">
.ell {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #0090ff;
    }
}
</style>
  